import React from "react"
import { Link } from "gatsby"
import {
  SectionStyled,
  TitleContainer,
  TitleH1,
  PointH3,
  ContentContainer,
  ContentLi,
  ContentP,
  Bold,
  Mail,
  MainDiv,
} from "../PrivacySection/PrivacyStyle"
import { Container } from "react-bootstrap"

function PrivacyPolicyNew({ props, propsLink, status }) {
  const isEnglish = propsLink.MainPage.includes("/en")
  const { Header } = props
  const {
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Article8,
    Article9,
    Article10,
    Article11,
    Article12,
    Article13,
    Article14,
    Article15,
  } = props.Article
  return (
    <>
      <ContentP>{Header.Desc1}</ContentP>
      <ContentP>{Header.Desc1a}</ContentP>
      <ContentP>
        {Header.Desc1b}{" "}
        <Mail href="mailto:hello@transfez.com">{Header.DescLink}</Mail>
      </ContentP>

      {/* NOMOR 1 */}
      <PointH3>{Article1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article1.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article1.Answer1.Desc1b}</ContentLi>
        <ContentLi>{Article1.Answer1.Desc1c}</ContentLi>
        <ContentLi>{Article1.Answer1.Desc1d}</ContentLi>
      </ol>

      {/* NOMOR 2 */}
      <PointH3>{Article2.Title}</PointH3>
      <ContentP>{Article2.Desc1a}</ContentP>
      <ContentP>{Article2.Desc1b}</ContentP>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article2.Answer1.Desc1a}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article2.Answer1.Desc1b}</ContentLi>
          <ContentLi>{Article2.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article2.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article2.Answer1.Desc1e}</ContentLi>
        </ul>
        <ContentLi>{Article2.Answer2.Desc1a}</ContentLi>
        {isEnglish && (
          <ul
            style={{
              paddingInlineStart: "20px",
              fontFamily: "Typefez-reg",
            }}
          >
            <ContentLi>{Article2.Answer2.Desc1b}</ContentLi>
            <ContentLi>{Article2.Answer2.Desc1c}</ContentLi>
            <ContentLi>{Article2.Answer2.Desc1d}</ContentLi>
          </ul>
        )}
        <ContentLi>{Article2.Answer2.Desc1e}</ContentLi>
        <ContentLi>{Article2.Answer2.Desc1f}</ContentLi>
      </ol>

      {/* NOMOR 3 */}
      <PointH3>{Article3.Answer1.Title}</PointH3>
      <ContentP>{Article3.Answer1.Desc1a}</ContentP>

      {/* NOMOR 4 */}
      <PointH3>{Article4.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article4.Answer1.Desc1a}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article4.Answer1.Desc1b}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1e}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1f}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1g}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1h}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1i}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1j}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1k}</ContentLi>
          <ContentLi>{Article4.Answer1.Desc1l}</ContentLi>
        </ul>
        <ContentLi>{Article4.Answer1.Desc1m}</ContentLi>
      </ol>

      {/* NOMOR 5 */}
      <PointH3>{Article5.Answer1.Title}</PointH3>
      <ContentP>{Article5.Answer1.Desc1a}</ContentP>
      <ul
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article5.Answer1.Desc1b}</ContentLi>
        <ContentLi>{Article5.Answer1.Desc1c}</ContentLi>
        <ContentLi>{Article5.Answer1.Desc1d}</ContentLi>
        <ContentLi>{Article5.Answer1.Desc1e}</ContentLi>
        <ContentLi>{Article5.Answer1.Desc1f}</ContentLi>
      </ul>

      {/* NOMOR 6 */}
      <PointH3>{Article6.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article6.Answer1.Desc1a}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article6.Answer1.Desc1b}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1e}</ContentLi>
          {isEnglish && (
            <>
              <ContentLi>{Article6.Answer1.Desc1f}</ContentLi>
              <ContentLi>{Article6.Answer1.Desc1g}</ContentLi>
              <ContentLi>{Article6.Answer1.Desc1h}</ContentLi>
            </>
          )}
        </ul>
        <ContentLi>{Article6.Answer1.Desc1i}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article6.Answer1.Desc1j}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1k}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1l}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1m}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1n}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1o}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1p}</ContentLi>
          <ContentLi>{Article6.Answer1.Desc1q}</ContentLi>
        </ul>
      </ol>

      {/* NOMOR 7 */}
      <PointH3>{Article7.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article7.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article7.Answer1.Desc1b}</ContentLi>
      </ol>

      {/* NOMOR 8 */}
      <PointH3>{Article8.Answer1.Title}</PointH3>
      <ContentP>{Article8.Answer1.Desc1a}</ContentP>

      {/* NOMOR 9 */}
      <PointH3>{Article9.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article9.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article9.Answer1.Desc1b}</ContentLi>
      </ol>

      {/* NOMOR 10 */}
      <PointH3>{Article10.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article10.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article10.Answer1.Desc1b}</ContentLi>
      </ol>

      {/* NOMOR 11 */}
      <PointH3>{Article11.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article11.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article11.Answer1.Desc1b}</ContentLi>
        <ContentLi>{Article11.Answer1.Desc1c}</ContentLi>
        <ContentLi>{Article11.Answer1.Desc1d}</ContentLi>
        <ContentLi>{Article11.Answer1.Desc1e}</ContentLi>
        <ContentLi>{Article11.Answer1.Desc1f}</ContentLi>
      </ol>

      {/* NOMOR 12 */}
      <PointH3>{Article12.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article12.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article12.Answer1.Desc1b}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article12.Answer1.Desc1c}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1d}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1e}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1f}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1g}</ContentLi>
        </ul>
        <ContentLi>{Article12.Answer1.Desc1h}</ContentLi>
        <ul
          style={{
            paddingInlineStart: "20px",
            fontFamily: "Typefez-reg",
          }}
        >
          <ContentLi>{Article12.Answer1.Desc1i}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1j}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1k}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1l}</ContentLi>
          <ContentLi>{Article12.Answer1.Desc1m}</ContentLi>
          {!isEnglish && <ContentLi>{Article12.Answer1.Desc1n}</ContentLi>}
        </ul>
      </ol>

      {/* NOMOR 13 */}
      <PointH3>{Article13.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>{Article13.Answer1.Desc1a}</ContentLi>
        <ContentLi>{Article13.Answer1.Desc1b}</ContentLi>
      </ol>

      {/* NOMOR 14 */}
      <PointH3>{Article14.Answer1.Title}</PointH3>
      <ol
        type="a"
        style={{
          paddingInlineStart: "20px",
          fontFamily: "Typefez-reg",
        }}
      >
        <ContentLi>
          {Article14.Answer1.Desc1a}{" "}
          <Mail href="mailto:dpo@transfez.com">
            {Article14.Answer1.Desc1Link}
          </Mail>
        </ContentLi>
        <ContentLi>{Article14.Answer1.Desc1b}</ContentLi>
      </ol>

      {/* NOMOR 15 */}
      <PointH3>{Article15.Answer1.Title}</PointH3>
      <ContentP>{Article15.Answer1.Desc1a}</ContentP>
    </>
  )
}

export default PrivacyPolicyNew
