import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import "../components/layout.css"
import Seo from "../components/SEOComponents/Seo"
import { useLocation } from "@reach/router"

import { NavigationBar } from "../components/NavigationBar/parent"
import PrivacySection from "../components/PrivacySection/PrivacyMain"
import Footer from "../components/FooterComponents/Footer"

import {
  MainPageJson,
  PrivacyJson,
  PathJson,
  PrivacyOldJson,
} from "../components/location"
import PrivacyPolicyNew from "../components/PrivacySection/PrivacyMainNew"
import { LayoutTextPage } from "../components/LayoutTextPage/parent"
import { NewPrivacyPolicy } from "../components/PrivacySection/NewPrivacyPolicy"

function Privacy() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const PrivacyContent = PrivacyJson()
  const PrivacyOldContent = PrivacyOldJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Header } = PrivacyContent || {}

  return (
    <div className="maxwidth">
      <Seo title="privacy-policy" />
      <NavigationBar />
      <LayoutTextPage
        title={Header.Title}
        secondTitle={Header.Title1}
        thirdTitle={Header.Title2}
      >
        {/* <PrivacyPolicyNew props={PrivacyContent} propsLink={PathContent} /> */}
        <NewPrivacyPolicy isEnglish={isEnglish} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default Privacy
