import React, { useEffect, useState } from "react"
import axios from "axios"
import "./service.css"

export const NewPrivacyPolicy = ({ isEnglish }) => {
  const [data, setData] = useState([])
  const [modifiedHtml, setModifiedHtml] = useState("")
  const [loading, setLoading] = useState(false)

  const modifyHtmlContent = (htmlContent, title) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, "text/html")

    // Add a class to all elements
    doc.querySelectorAll("*").forEach(el => {
      el.classList.add("privacy-policy-content")
    })

    // Adding the title to the content
    const titleElement = doc.createElement("h2")
    titleElement.textContent = title
    titleElement.classList.add("privacy-policy-title")
    doc.body.insertBefore(titleElement, doc.body.firstChild)

    // Return the modified HTML as a string
    return doc.body.innerHTML
  }

  const FetchData = async () => {
    const url = process.env.GATSBY_ROOT_URL_ENV // Ensure this environment variable is set in your project
    try {
      setLoading(true)
      const response = await axios.get(
        `${url}/api/tnc/get_privacy_policy_of_remittance_service_for_web`
      )
      setData(response.data.privacy_policy) // Assuming 'privacy_policy' is the array containing the data
    } catch (error) {
      console.error("Error fetching data: ", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    FetchData()
  }, [])

  useEffect(() => {
    if (data.length > 0) {
      const modifiedContents = data.map(
        section => {
          const title = isEnglish ? section.title_eng : section.title_idn
          const content = isEnglish ? section.content_eng : section.content_idn
          return modifyHtmlContent(content, title)
        } // Assuming 'content_idn' and 'title_idn' are the keys you want to modify and render
      )
      setModifiedHtml(modifiedContents.join(" ")) // Joining all sections into one HTML string
    }
  }, [data])

  // console.log(data)
  // data.forEach(item => {
  //   console.log(item.content_idn)
  // })

  // Rendering the modified HTML or a loading indicator
  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: modifiedHtml }}
          className="privacy-policy-container"
        />
      )}
    </div>
  )
  return <div>{data.content_idn}</div>
}

export default NewPrivacyPolicy
