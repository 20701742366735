import React from "react"
import { Link } from "gatsby"
import {
  SectionStyled,
  TitleContainer,
  TitleH1,
  PointH3,
  ContentContainer,
  ContentLi,
  ContentP,
  Bold,
  Mail,
} from "./PrivacyStyle"

function PrivacyMain({ props, propsLink, status }) {
  const lang = propsLink.MainPage.includes("/en")
  const { Header } = props
  const {
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Article8,
    Article9,
    Article10,
    Article11,
    Article12,
    Article13,
    Article14,
    Article15,
    Article16,
  } = props.Article
  return (
    <>
      <SectionStyled
        className="blog-article"
        style={status ? { paddingTop: "80px" } : null}
      >
        <TitleContainer>
          <TitleH1>{Header.Title}</TitleH1>
        </TitleContainer>
        <section className="content">
          <ContentContainer style={{ maxWidth: "800px" }}>
            <article className="blog-content">
              {/*  */}
              <ContentP>{Header.Desc1a}</ContentP>
              <ContentP>
                {Header.Desc1b}
                <Mail href="mailto:hello@transfez.com">{Header.Desc1c}</Mail>
              </ContentP>
              {/*  */}
              {/* PERTAMA */}

              <PointH3>{Article1.Title}</PointH3>
              <ol
                type="1"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article1.Answer1.Desc1a}</ContentLi>
                <ContentLi>{Article1.Answer1.Desc1b}</ContentLi>
                <ContentLi>{Article1.Answer1.Desc1c}</ContentLi>
                <ContentLi>{Article1.Answer1.Desc1d}</ContentLi>
              </ol>

              {/* KEDUA */}

              <PointH3>{Article2.Title}</PointH3>
              <ContentP>{Article2.Desc1a}</ContentP>
              <ContentP>{Article2.Desc1b}</ContentP>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article2.Answer1.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article2.Answer1.Desc1b}</ContentLi>
                  <ContentLi>{Article2.Answer1.Desc1c}</ContentLi>
                  <ContentLi>{Article2.Answer1.Desc1d}</ContentLi>
                </ul>
                <ContentLi>{Article2.Answer2.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article2.Answer2.Desc1b}</ContentLi>
                  <ContentLi>{Article2.Answer2.Desc1c}</ContentLi>
                  <ContentLi>{Article2.Answer2.Desc1d}</ContentLi>
                </ul>
                <ContentLi>{Article2.Answer3.Desc1a}</ContentLi>
                {/* <ul
                  type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article2.Answer3.Desc1b}</ContentLi>
                  <ContentLi>{Article2.Answer3.Desc1c}</ContentLi>
                  <ContentLi>{Article2.Answer3.Desc1d}</ContentLi>
                  <ContentLi>{Article2.Answer3.Desc1e}</ContentLi>
                </ul> */}
                <ContentLi>{Article2.Answer4.Desc1a}</ContentLi>
              </ol>

              {/* KETIGA */}

              <PointH3>{Article3.Title}</PointH3>
              <ContentP>{Article3.Desc1a}</ContentP>
              <ContentP>{Article3.Desc1b}</ContentP>
              {/* <Link to={propsLink.MainPage}>{Article3.Desc1b}</Link>
                {Article3.Desc1c} */}

              {/* KEEMPAT */}

              <PointH3>{Article4.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article4.Answer1.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article4.Answer1.Desc1b}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1c}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1d}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1e}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1f}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1g}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1h}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1i}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1j}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1k}</ContentLi>
                  <ContentLi>{Article4.Answer1.Desc1l}</ContentLi>
                </ul>
                {lang ? (
                  <ContentLi>{Article4.Answer2.Desc1a}</ContentLi>
                ) : (
                  <>
                    {" "}
                    <ContentLi>{Article4.Answer2.Desc1a}</ContentLi>
                    <ContentLi>{Article4.Answer3.Desc1a}</ContentLi>
                    <ContentLi>{Article4.Answer4.Desc1a}</ContentLi>
                  </>
                )}
              </ol>
              <ContentP>{Article4.Answer5.Desc1a}</ContentP>

              {/* KELIMA */}

              <PointH3>{Article5.Title}</PointH3>
              <ContentP>{Article5.Desc1a}</ContentP>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article5.Desc1b}</ContentLi>
                <ContentLi>{Article5.Desc1c}</ContentLi>
                <ContentLi>{Article5.Desc1d}</ContentLi>
                <ContentLi>{Article5.Desc1e}</ContentLi>
                <ContentLi>{Article5.Desc1f}</ContentLi>
              </ol>

              {/* KEENAM */}

              <PointH3>{Article6.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article6.Answer1.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article6.Answer1.Desc1b}</ContentLi>
                  <ContentLi>{Article6.Answer1.Desc1c}</ContentLi>
                  <ContentLi>{Article6.Answer1.Desc1d}</ContentLi>
                  <ContentLi>{Article6.Answer1.Desc1e}</ContentLi>
                  <ContentLi>{Article6.Answer1.Desc1f}</ContentLi>
                  <ContentLi>{Article6.Answer1.Desc1g}</ContentLi>
                  {lang ? (
                    <ContentLi>{Article6.Answer1.Desc1h}</ContentLi>
                  ) : (
                    <></>
                  )}
                </ul>
                <ContentLi>{Article6.Answer2.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article6.Answer2.Desc1b}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1c}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1d}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1e}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1f}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1g}</ContentLi>
                  <ContentLi>{Article6.Answer2.Desc1h}</ContentLi>
                  {lang ? (
                    <ContentLi>{Article6.Answer2.Desc1i}</ContentLi>
                  ) : (
                    <></>
                  )}
                </ul>
              </ol>

              {/* KETUJUH */}

              <PointH3>{Article7.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentP>{Article7.Desc1a}</ContentP>
                <ContentP>{Article7.Desc1b}</ContentP>
              </ol>

              {/* KEDELAPAN */}

              <PointH3>{Article8.Title}</PointH3>
              <ContentP>{Article8.Desc1a}</ContentP>

              {/* KESEMBILAN */}

              <PointH3>{Article9.Title}</PointH3>
              <ContentP>{Article9.Desc1a}</ContentP>

              {/* KESEPULUH */}

              <PointH3>{Article10.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article10.Desc1a}</ContentLi>
                <ContentLi>{Article10.Desc1b}</ContentLi>
              </ol>

              {/* KESEBELAS */}

              <PointH3>{Article11.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>
                  {Article11.Desc1a}
                  <Mail href="mailto:hello@transfez.com">
                    {Article11.Desc1b}
                  </Mail>
                  {Article11.Desc1c}
                </ContentLi>
                <ContentLi>{Article11.Desc1d}</ContentLi>
                <ContentLi>{Article11.Desc1e}</ContentLi>
                <ContentLi>{Article11.Desc1f}</ContentLi>
                <ContentLi>{Article11.Desc1g}</ContentLi>
                <ContentLi>{Article11.Desc1h}</ContentLi>
              </ol>

              {/* KEDUA BELAS */}

              <PointH3>{Article12.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article12.Answer1.Desc1a}</ContentLi>
                <ContentLi>{Article12.Answer2.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article12.Answer2.Desc1b}</ContentLi>
                  <ContentLi>{Article12.Answer2.Desc1c}</ContentLi>
                  <ContentLi>{Article12.Answer2.Desc1d}</ContentLi>
                  <ContentLi>{Article12.Answer2.Desc1e}</ContentLi>
                  <ContentLi>{Article12.Answer2.Desc1f}</ContentLi>
                </ul>
                <ContentLi>{Article12.Answer3.Desc1a}</ContentLi>
                <ul
                  // type="a"
                  style={{
                    paddingInlineStart: "20px",
                    fontFamily: "GothamLight",
                  }}
                >
                  <ContentLi>{Article12.Answer3.Desc1b}</ContentLi>
                  <ContentLi>{Article12.Answer3.Desc1c}</ContentLi>
                  <ContentLi>{Article12.Answer3.Desc1d}</ContentLi>
                  <ContentLi>{Article12.Answer3.Desc1e}</ContentLi>
                  <ContentLi>{Article12.Answer3.Desc1f}</ContentLi>
                  {lang ? (
                    <></>
                  ) : (
                    <ContentLi>{Article12.Answer3.Desc1g}</ContentLi>
                  )}
                </ul>
              </ol>

              {/* KETIGA BELAS */}

              <PointH3>{Article13.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>{Article13.Desc1a}</ContentLi>
                <ContentLi>{Article13.Desc1b}</ContentLi>
              </ol>

              {/* KEEMPAT BELAS */}

              <PointH3>{Article14.Title}</PointH3>
              <ol
                type="a"
                style={{
                  paddingInlineStart: "20px",
                  fontFamily: "GothamLight",
                }}
              >
                <ContentLi>
                  {Article14.Desc1a}
                  <Mail href="mailto:hello@transfez.com">
                    {Article14.Desc1b}
                  </Mail>
                </ContentLi>
                <ContentLi>{Article14.Desc1c}</ContentLi>
              </ol>

              {/* KELIMA BELAS */}

              <PointH3>{Article15.Title}</PointH3>
              <ContentP>{Article15.Desc1a}</ContentP>
            </article>
          </ContentContainer>
        </section>
      </SectionStyled>
    </>
  )
}

export default PrivacyMain
